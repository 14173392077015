<template>
  <q-page-sticky
    position="top-right"
    :offset="[18, 18]"
    style="z-index: 99999; margin-top: 0; margin-right: 0"
  >
    <q-card class="bg-white q-pa-sm" style="width: 210px; height: 100vh">
      <div class="row">
        <div style="font-size: 10pt" class="text-uppercase text-grey-8">
          carrinho<br /><span style="font-size: 9pt"
            >itens: {{ getCheckoutData.length }}</span
          >
        </div>
        <q-space />
        <q-btn
          flat
          round
          dense
          icon="close"
          color="red"
          @click="closeCheckout()"
          style="margin-top: -10pt"
        />
      </div>
      <q-separator />

      <div class="bg-grey-3 q-pr-sm q-pl-sm">
        <q-scroll-area
          :visible="getCheckoutData.length > 2"
          :class="{
            'q-pr-xs q-pl-xs': getCheckoutData.length > 2,
          }"
          class="scroll-height"
          :bar-style="barStyle"
          :thumb-style="thumbStyle"
          ><div v-if="showCards">
            <template
              v-for="(el, i) in getCheckoutData"
              :key="`${i}-checkout-item`"
            >
              <checkout-item
                :product="el"
                class="q-mb-sm q-mt-sm"
                @updateQuantity="loadCards()"
              />
            </template>
          </div>
          <div class="q-pa-md" v-else>
            <div class="text-center">
              <q-spinner color="grey" size="3em" />
            </div>
          </div>
        </q-scroll-area>
      </div>

      <q-separator />
      <div class="q-mt-lg">
        <p
          v-if="getCheckoutData.length > 0"
          style="font-size: 10pt; cursor: pointer"
          class="text-red q-mt-sm q-mb-sm text-center"
          @click="clearAndClose()"
        >
          Limpar carrinho
        </p>

        <q-btn
          dense
          color="black"
          class="full-width"
          icon="computer"
          label="Acessar"
          style="font-size: 10pt"
          @click="generateLink('access')"
          :loading="in_request_access"
          :disable="getCheckoutData.length === 0"
        />

        <q-btn
          dense
          color="primary"
          class="copy-btn full-width q-mt-sm"
          icon="content_copy"
          label="Copiar"
          style="font-size: 10pt"
          @click="generateLink('copy')"
          :loading="in_request_copy"
          :disable="getCheckoutData.length === 0"
        />
      </div>
      <div class="q-mt-md">
        <span class="text-grey-8">Subtotal: </span>
        <span class="text-blue-7">{{
          toMoneyLabel(getCheckoutDataSubTotal)
        }}</span>
      </div>
    </q-card>
  </q-page-sticky>
</template>

<script>
import { formatCheckoutByMac } from "@/shared/helpers/checkoutHelpers";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers, useGetters } from "vuex-composition-helpers";
import { notifySuccess, notifyError } from "@/shared/helpers/notify";
import CheckoutItem from "./CheckoutItem.vue";
import { ref } from "vue";

const ProductStore = createNamespacedHelpers("products");

export default {
  name: "ProductsCheckout",

  components: { CheckoutItem },

  props: {
    products: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const slide = ref(`slide-0`),
      showCards = ref(true),
      in_request_access = ref(false),
      in_request_copy = ref(false);

    const {
        getCheckoutData,
        getCheckoutDataSubTotal,
        // getCheckoutLink,
        getCheckoutUrlData,
      } = ProductStore.useGetters([
        "getCheckoutData",
        "getCheckoutDataSubTotal",
        // "getCheckoutLink",
        "getCheckoutUrlData",
      ]),
      { clearCheckoutData, closeCheckoutSide, fetchGenerateCheckoutLink } =
        ProductStore.useActions([
          "clearCheckoutData",
          "closeCheckoutSide",
          "fetchGenerateCheckoutLink",
        ]);

    const { userSlug } = useGetters({
      userSlug: "auth/userSlug",
    });

    const closeCheckout = () => closeCheckoutSide();

    const clearAndClose = () => {
      clearCheckoutData();
      closeCheckoutSide();
      notifySuccess("Carrinho limpo com sucesso!");
    };

    const generateLink = (type) => {
      if (type === "copy") in_request_copy.value = true;
      else in_request_access.value = true;

      const payload = {
        products: getCheckoutData.value.map((e) => ({
          sku: e.prod_ref,
          quantity: e.quantity,
        })),
      };

      // TODO:: analisar problema de copy para que seja utilizado com
      // o serviço do backend de forma abstrata, de forma que seja
      // possível trabalhar com a formatação de outras marcas
      const checkoutLink = formatCheckoutByMac(
        payload,
        getCheckoutUrlData.value,
        userSlug.value
      );

      if (type === "copy") copyLink(checkoutLink);
      else accessLink(checkoutLink);

      if (type === "copy") in_request_copy.value = false;
      else in_request_access.value = false;
    };

    const accessLink = (link) => window.open(link, "_blank");

    const loadCards = () => {
      showCards.value = false;
      setTimeout(() => (showCards.value = true), 100);
    };

    function copyLink(link) {
      if (!link) {
        console.error("Nenhum link fornecido para cópia.");
        notifyError("Nenhum link fornecido para cópia.");
        return;
      }

      // Usando a API Clipboard para copiar o texto
      navigator.clipboard
        .writeText(link)
        .then(() => {
          notifySuccess("Link copiado com sucesso!");
        })
        .catch((err) => {
          console.error("Erro ao copiar o link:", err);
          notifyError("Erro ao copiar o link.");
        });
    }

    return {
      barStyle: {
        right: "0px",
        width: "2px",
      },
      thumbStyle: {
        right: "0px",
        width: "2px",
      },

      // ------------------------
      slide,
      showCards,
      toMoneyLabel,
      in_request_access,
      in_request_copy,
      getCheckoutData,
      clearCheckoutData,
      getCheckoutUrlData,
      getCheckoutDataSubTotal,
      fetchGenerateCheckoutLink,

      loadCards,
      closeCheckout,
      clearAndClose,
      generateLink,
      copyLink,
      accessLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.clear-btn {
  font-size: 10pt;
}
.p-description {
  margin: 9px 0 0;
  color: #28283b;
  text-align: center;
  font-weight: 300;
  transition: color 1s;
  flex: 1;
  padding: 5px;
  font-size: 9pt;
}
@media (min-width: 1820px) {
  .scroll-height {
    height: 70vh;
  }
}

@media (max-width: 1820px) {
  .scroll-height {
    height: 60vh;
  }
}
</style>
